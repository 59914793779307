import { pink, blue } from '@material-ui/core/colors';

export const altName = "shakai"
export const dbName = "fukushi"
export const urlPref = "/ss"
export const headerColor = pink[100]
export const topHeaderColor = pink[400]
export const toolbarColor = blue[400]
export const gakushuDefaultCategory = null
export const gakushuFirstUrl = "/my/lesson/query/select-category"
export const categoryLabels = ["共通科目", "社会福祉士・専門", "精神保健福祉士・専門"]
export const categoryJireiFlags = [false, false, true]
export const switchContentMenuLabel = "介護福祉士へ"
export const switchContentMenuUrl = "/k"
export const jireiLabel = "事例問題"
export const enquateShakaiLabel = "第38回社会福祉士試験を受験"
export const enquateSeishinLabel = "第28回精神保健福祉士試験を受験"
export const nendoList = [
	{"value": 2024, "label": "2024年", "checked": true, "disabled": false},
	{"value": 2023, "label": "2023年", "checked": true, "disabled": false},
	{"value": 2022, "label": "2022年", "checked": true, "disabled": false},
	{"value": 2021, "label": "2021年", "checked": true, "disabled": false},
	{"value": 2020, "label": "2020年", "checked": true, "disabled": false},
	{"value": 2019, "label": "2019年", "checked": true, "disabled": false},
	{"value": 2018, "label": "2018年", "checked": true, "disabled": false},
	// {"value": 2017, "label": "2017年", "checked": true, "disabled": false},
	// {"value": 2016, "label": "2016年", "checked": true, "disabled": false},
	// {"value": 2015, "label": "2015年", "checked": true, "disabled": false},
	// {"value": 2014, "label": "2014年", "checked": true, "disabled": false},
	// {"value": 2013, "label": "2013年", "checked": true, "disabled": false},
	// {"value": 2012, "label": "2012年", "checked": true, "disabled": false},
]
export const licenseList = {
	"0": "お試し",
	"01": "社会福祉士",
	"02": "精神保健福祉士",
	"1": "社会福祉士・専門",
	"2": "精神保健福祉士・専門",
	"012": "社会福祉士・精神保健福祉士",
	"8": "自動採点利用",
	"9": "お問い合わせください",
}
// export const purpleMoshiServiceCode = "201303"
